/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 10:29:19
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-20 10:42:53
*/
import Agent from './Agent.vue'
export default Agent
