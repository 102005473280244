/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 10:29:29
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-20 11:23:28
 */
import AgentTable from './AgentTable'
export default {
  name: 'AdminAgent',
  components: {
    AgentTable
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
